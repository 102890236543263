import React from "react";
import "../styles/policy.css";
import brickscontainer from "../assets/brickscontainer.jpg";
import logoheader from "../assets/logoheader.jpg";
import { Link } from "gatsby";

const TermsConditions = () => {
  return (
    <div className="mainContainer">
      <img src={brickscontainer} alt="Bricks Container" />
      <div className="contentContainer">
        <img src={logoheader} alt="Logo" />
        <h3>
          BAR-S FOODS NOW THAT SOUNDS GOOD – ICONIC SUMMER MASHUP SWEEPSTAKES
        </h3>
        <h3>OFFICIAL RULES</h3>
        <h3>
          NO PURCHASE NECESSARY TO ENTER, WIN OR CLAIM A PRIZE. A PURCHASE OR
          <span>
            PAYMENT WILL NOT INCREASE AN ENTRANT’S CHANCES OF WINNING.
          </span>
        </h3>
        <p>
          The Bar-S Foods “Now That Sounds Good – Iconic Summer Mashup
          Sweepstakes” (“Sweepstakes”) is sponsored by Bar-S Foods Company, 5090
          N 40th Street, Suite 300, Phoenix AZ 85018 (“Sponsor”).
        </p>
        <div className="policy">
          <span>1.</span>
          <p>
            <span>SWEEPSTAKES PERIOD:</span> The Sweepstakes begins on May 31,
            2021 at 12:00AM (midnight) Eastern Time (“ET”) and ends on June 21,
            2021 at 11:59:59 PM ET (“Sweepstakes Period”). The Sponsor’s
            computer is the Sweepstakes’ official clock.{" "}
          </p>
        </div>
        <div className="policy">
          <span>2.</span>
          <p>
            <span>ELIGIBILITY:</span> This Sweepstakes is open only to legal
            residents of one of the fifty (50) United States (including the
            District of Columbia) and who are at least twenty-one (21) years old
            at the time of entry. Employees of Sponsor, Heart & Soul Marketing,
            Mercury Promotions and Fulfillment (“Mercury”) and each of their
            respective parent companies, subsidiaries, affiliates, advertising
            and promotional agencies (collectively, “Sweepstakes Entities”) and
            each of their immediate family members (e.g., spouse, parent, child,
            sibling, and their respective spouses and the “steps” of each,
            regardless of where they reside) and persons living in the same
            household of each, whether or not related, are not eligible to enter
            or win. All federal, state, and local laws apply. Void where
            prohibited or restricted by law. All people who are eligible to
            participate in this Sweepstakes are referred to as “Entrants.”
            <span>
              Participation in the Sweepstakes constitutes Entrant’s full and
              unconditional agreement to these Official Rules and Sponsor’s
              decisions and interpretations, which are final and binding in all
              matters related to the Sweepstakes. For purposes of the
              Sweepstakes, an Entrant’s e-mail address will be the e-mail
              address submitted at the time of entry. Entrants will not be
              allowed to change their e-mail address.
            </span>
          </p>
        </div>
        <div className="policy2">
          <span>3. HOW TO ENTER THE SWEEPSTAKES:</span>
          <div className="policy">
            <span>A.</span>
            <p>
              <span>How to Enter:</span>
              During the Sweepstakes Period, an Entrant must visit
              <Link
                className="link"
                to="https://www.bar-s.com/my-iconic-summer"
              >
                {" "}
                https://www.bar-s.com/my-iconic-summer
              </Link>{" "}
              (the “Website”) and he/she must complete and submit the
              registration form including, but not limited to: his/her complete
              first and last name (no initials), street address (No P.O. Boxes),
              city, state, ZIP Code, valid e-mail address, telephone number,
              confirmation that he/she is at least twenty-one (21) years old,
              and that he/she has read and agreed to the Official Rules.
              <span>
                Upon the successful submission of the registration information,
                an Entrant must follow the on-screen prompts to upload a fifteen
                to thirty second (:15–:30) video (the “Video”) that expresses
                his/her desire to be featured in the Bar-S Foods and Vanilla
                Ice’s Baby Got Buns music video (the “Music Video”). In the
                Video, the Entrant must: (i) show or tell why he/she deserve to
                be Bar-S Foods Grand Prize winner and appear in the Music Video
                with Vanilla Ice and (ii) use the phrase “Word to your mustard!”{" "}
              </span>
            </p>
          </div>
          <div className="policy">
            <span>B.</span>
            <p>
              <span>Video Guidelines and Restrictions:</span> Each Video
            </p>
          </div>
          <div className="policy3">
            <ul>
              <li> Must be the Entrant’s original video. </li>
              <li> Must not exceed a maximum of 1GB. </li>
              <li> Must be either a mp4m, .mov or .avi file. </li>
              <li>
                {" "}
                Must not contain any music unless it is Entrant’s original song.{" "}
              </li>
              <li>
                {" "}
                Must be created solely by the Entrant, and may not be
                plagiarized, have been previously published or have previously
                won a prize or award.
              </li>
              <li>
                {" "}
                Must be in English, except for commonly understood foreign
                words.{" "}
              </li>
              <li>
                {" "}
                Must follow the instructions and comply with these Official
                Rules.{" "}
              </li>
              <li>
                {" "}
                Must not have been submitted previously in a promotion of any
                kind or exhibited or displayed publicly through any means.{" "}
              </li>
              <li>
                {" "}
                Must not include individuals other than the Entrant without
                prior approval from all participants. Entrant must be able to
                provide written proof of permission from any identifiable
                individual upon Sponsor’s request. If any such person is a minor
                the Entrant must be his/her parent/legal guardian.{" "}
              </li>
              <li>
                {" "}
                Must not disparage Sponsor, Mercury, or any other person or
                party affiliated with the promotion and administration of this
                Sweepstakes.{" "}
              </li>
              <li>
                {" "}
                Must not contain material that violates or infringes another's
                rights, including, but not limited to, privacy, publicity or
                intellectual property rights, including copyright infringement
                (including, but not limited to logos, trade names, or patented
                features). Sponsor may, in its sole and absolute discretion,
                blur any item that violates the foregoing and enter the Video.{" "}
              </li>
              <li>
                {" "}
                Must not include any third-party logos or marks, except the
                Sponsor’s logo/marks, which the Sponsor provides a limited
                release to Entrants for purposes of entering the Sweepstakes
                only. Sponsor may, in its sole and absolute discretion, blur any
                item that violates the foregoing and enter the Video.{" "}
              </li>
              <li>
                {" "}
                Must not include personally identifiable information, including
                but not limited to social media handles, phone numbers or e-mail
                addresses.{" "}
              </li>
              <li>
                {" "}
                Must not contain material that is inappropriate, indecent, lewd,
                pornographic, obscene, hateful, violent, tortious, defamatory,
                slanderous or libelous (as determined by Sponsor at Sponsor’s
                sole discretion).{" "}
              </li>
              <li>
                {" "}
                Must not contain, facilitate, reference, or use material that
                promotes bigotry, racism, hatred or harm against any group or
                individual or promotes discrimination based on race, gender,
                ethnicity, religion, nationality, disability, sexual
                orientation, political orientation, citizenship, ancestry,
                marital status, or age (as determined by Sponsor in its sole
                discretion).{" "}
              </li>
              <li>
                {" "}
                Must not contain, facilitate, reference, or use material that
                promotes illegal drug use, alcohol abuse, or other illegal
                activity.{" "}
              </li>
              <li>
                {" "}
                Must not contain material or statements that promote use of
                alcohol by minors, illegal drugs, tobacco, firearms/weapons (or
                the use of any of the foregoing), any activities that may appear
                unsafe or dangerous, or any particular political agenda or
                message.{" "}
              </li>
              <li>
                {" "}
                Must not contain any viruses, spyware, malware, or other
                malicious components that are designed to harm the functionality
                of a computer in any way.
              </li>
              <li>
                Must not contain material that is unlawful, in violation of or
                contrary to the laws or regulations in any jurisdiction where
                entry is created.
              </li>
            </ul>
            <p>
              By submitting a Video, each Entrant agrees that his/her Video
              conforms to the Guidelines and Restrictions set forth above and
              that Sponsor may, at their sole and absolute discretion,
              disqualify him/her from the Sweepstakes if Sponsor decides that
              his/her Video fails to conform to such requirements or any other
              provision of these Official Rules. Completion of registration and
              uploading a valid Video constitutes an “Entry.”
            </p>
          </div>
          <div className="policy">
            <span>C.</span>
            <p>
              <span>
                Limit one (1) Entry per Entrant during the entire Sweepstakes
                Period.
              </span>
            </p>
          </div>
          <div className="policy">
            <span>D.</span>
            <p>
              <span>Usage:</span>Submitting an Entry constitutes Entrant’s grant
              to Sponsor and its respective parents and affiliates, and the
              employees, officers, directors, licensees, designees, agents,
              successors and assigns (collectively, “Grantees”), of a
              royalty-free, irrevocable, perpetual, nonexclusive license to use,
              reproduce, modify, post, distribute, publish or create derivative
              works from and display the Video in whole or in part, on a
              worldwide basis, and to incorporate it into other works, in any
              form, media or technology now known or later developed, including
              for promotional, advertising, public relations, trade show and/or
              marketing purposes, without further compensation, notification or
              permissions of any kind except where prohibited by law. If
              requested, Entrant will sign any documentation that may be
              required for Sponsor or its respective designees to make use of
              the nonexclusive rights Entrant is granting to use the Video. This
              license expressly includes a right (but not the obligation) for
              Sponsor to modify the Video to remove any third party intellectual
              property. Sponsor shall have the right, in its sole discretion, to
              edit, composite, morph, scan, duplicate, or alter the Entry for
              any purpose which Sponsor deems necessary or desirable, and each
              Entrant irrevocably waives any and all so-called moral rights they
              may have therein. Each Entrant hereby acknowledges that such
              Entrant does not reserve any rights in or to the Entry. The
              displaying of a Video on any website/social media platform does
              not constitute verification of eligibility or compliance with
              these Official Rules and all Videos and Entrants remain subject to
              verification of eligibility and compliance with these Official
              Rules as determined by Sponsor at its sole discretion
            </p>
          </div>
        </div>
        <div className="policy">
          <span>4.</span>
          <p>
            <span>RANDOM SWEEPSTAKES DRAWING:</span>On or about June 23, 2021,
            Mercury will randomly select one (1) potential “Grand Prize”
            (defined in Rule 5 of these Official Rules) winner from all eligible
            entries received during the Sweepstakes Period. The potential “Grand
            Prize” winner is considered a potential winner pending verification
            of his/her eligibility and full and complete compliance, with these
            Official Rules (including but not limited to the Video). The Sponsor
            and its representative’s decisions are final and binding on all
            matters relating to this Sweepstakes.
          </p>
        </div>
        <div className="policy2">
          <span>
            5. GRAND PRIZE, APPROXIMATE RETAIL VALUE, AND ODDS OF WINNING:{" "}
          </span>
          <div className="policy">
            <span>A.</span>
            <p>
              <span>Grand Prize (1):</span>
              The “Grand Prize” is a three (3) day/two (2) night trip for up to
              two (2) people (the Grand Prize winner and one (1) guest of
              his/her choosing subject to the restrictions herein) to Los
              Angeles, California to attend the shooting of the Music Video. The
              Grand Prize winner and guest must arrive in Los Angeles on July
              11, 2021 to appear in the Music Video and depart on July 13, 2021.
              The guest must be at least 16 years old and be willing appear in
              the Music Video. If the guest is under 18 years old, then the
              guest must be the child/legal ward of the Grand Prize winner.
              <span>
                The Grand Prize includes round-trip coach class air
                transportation from a major commercial airport near the Grand
                Prize winner's residence (selected in Sponsor's sole discretion)
                to the Los Angeles International Airport, two (2) night stay at
                a hotel selected by the Sponsor in its sole and absolute
                discretion (single room double occupancy). The Grand Prize
                winner will receive an Uber gift card to use for ground
                transportation to/from Los Angeles International Airport to the
                hotel and Sponsor to arrange for an Uber ride to/from hotel to
                Music Video shoot. The Grand Prize does not include any other
                food, travel or other allowances.
              </span>
              <span>
                The guest must execute a Liability and Publicity release (unless
                prohibited by law) as provided by Sponsor or its agent. If the
                guest is a minor, he/she must be the minor child/ward of the
                Grand Prize winner and his/her parent/legal guardian must
                execute the Liability & Publicity Release (unless prohibited by
                law) on the minor's behalf. If a Grand Prize winner elects to
                participate without a guest, no additional compensation will be
                awarded.
              </span>
              <span>
                The Grand Prize winner and guest agrees that they may be
                included in a Music Video and that they will sign any and all
                documents for the use of their images in the Music Video as a
                requirement for receiving the Grand Prize. The Grand Prize
                winner and guest to be available for media interviews as needed
                and will receive public relations messaging training in advance.
              </span>
              <span>
                The Grand Prize winner and his/her guest are solely responsible
                for obtaining any desired or necessary travel documents
                (including without limitation a valid government-issued
                photographic identification). If a winner resides within 250
                miles of the Los Angeles, California metropolitan area (as
                determined by Sponsor in its sole discretion), Sponsor may
                substitute ground transportation in lieu of airfare and no
                additional compensation will be awarded to the winner.
              </span>
              <span>
                All other costs, fees and expenses not expressly set forth in
                these Official Rules are not included in the Grand Prize. The
                Grand Prize winner will be responsible for all gratuities,
                meals, ground transportation, and any other incidental costs or
                expenses not identified in these Official Rules. Travel must be
                taken on dates specified by Sponsor, which may be modified in
                Sponsor’s sole discretion. The Grand Prize winner and guest must
                travel on the same itinerary. The Grand Prize winner and his/her
                guest hereby acknowledges that Sponsor has not and will not
                obtain or provide travel insurance or any other form of
                insurance for any part of the Grand Prize. The Grand Prize
                winner will be required to provide a major credit card upon
                hotel check-in, and all in-room charges will be charged to the
                credit card. Any damage to the room will be the responsibility
                of the winner.{" "}
              </span>
              <span>
                Sponsor is not responsible if the Music Video is delayed,
                postponed, or canceled for any reason. Grand Prize winner will
                not receive compensation from Sponsor if the Music Video is
                delayed or canceled.{" "}
              </span>
              <span>
                The Approximate Retail Value ("ARV") of the Grand Prize is Two
                Thousand Five Hundred Dollars ($2,500.00). The actual value of
                the Grand Prize may vary depending on point of departure and
                fluctuations in the cost of air transportation. Any difference
                between the estimated ARV and the actual value of a Grand Prize
                will not be awarded. No more than one (1) Grand Prize will be
                awarded.
              </span>
              <span>
                <b>NOTE: </b>If due to COVID-19 or other similar restrictions,
                the Music Video shoot is canceled or delayed or travel to Los
                Angeles California is interrupted or for any other reason
                Sponsor believes it is unable to award the Grand Prize, the
                Sponsor may give the Grand Prize winner a cash prize of Two
                Thousand Five Hundred Dollars ($2,500.00). In addition, the
                Grand Prize winner and guest must comply with all COVID-19
                testing, masking, social distancing and all other guidelines and
                protocols established by the airlines, airports, the hotel, the
                Music Video shoot, transportation services, any other vendors or
                providers for the Grand Prize, and/or any governmental entity.
                If the Grand Prize winner and/or guest do not comply with these
                protocols or if the Grand Prize winner and/or guest tests
                positive for COVID 19 when participating in the Grand Prize,
                then the Grand Prize or any portion thereof may not be awarded.
              </span>
            </p>
          </div>
          <div className="policy">
            <span>B.</span>
            <p>
              <span>General Conditions on Prizes:</span>
              Each Uber Card is subject to the terms and conditions set forth on
              the Uber Card or online. The use of Uber Card constitutes the
              user’s acceptance of the voucher/gift card’s terms and conditions
              so each winner should read the terms and conditions on the Uber
              Card carefully. Each Uber Card will not be redeemed or exchanged
              for cash, except where required by law. Each Uber Card will not be
              replaced or replenished if it lost, stolen, damaged, destroyed or
              used without your permission and will be voided if altered or
              defaced. The Grand Prize is non-transferrable and
              non-substitutable, except by Sponsor in its sole discretion.
              Sponsor reserves the right to substitute a similar prize of
              comparable or greater value. All taxes and other expenses, costs,
              or fees associated with the acceptance and/or use of a prize(s)
              are the sole responsibility of Grand Prize winner.
            </p>
          </div>
          <div className="policy">
            <span>C.</span>
            <p>
              <span>Odds of Winning:</span>The odds of winning the Grand Prize
              depend on the total number of eligible entries received during the
              entire Sweepstakes Period.
            </p>
          </div>
        </div>
        <div className="policy">
          <span>6.</span>
          <p>
            <span>WINNER NOTIFICATION AND VERIFICATION:</span>The potential
            Grand Prize winner is subject to verification of eligibility and
            compliance with these Official Rules. The potential Grand Prize
            winner will receive notification from Mercury via e-mail on or about
            June 25, 2021. The potential Grand Prize winner will be required to
            sign and return an Affidavit of Eligibility and Liability/Publicity
            Release (except where prohibited) (the “Affidavit”) and any other
            requested documents within five (5) calendar days from the date of
            attempted notification, or the Grand Prize may be forfeited in its
            entirety and awarded to an alternate winner, at the Sponsor’s sole
            and absolute discretion.
            <span>
              The Grand Prize winner agrees to keep his/her Grand Prize
              confidential and will not promote his/her winning status and/or
              Video on social media until permission is granted from Sponsor.
              The Grand Prize winner acknowledges that the Affidavit will
              include a confidentiality provision.
            </span>
            <span>
              If any potential winner is disqualified, found to be ineligible or
              not in compliance with these Official Rules, declines to accept a
              prize, or in the event that a winner fails to return any required
              paperwork within the allotted timeframe (if applicable), the prize
              may be forfeited. If a prize is forfeited for any reason, it may
              be awarded to an alternate potential winner, at the Sponsor’s sole
              and absolute discretion, selected at random from the remaining
              pool of eligible entries received during the Sweepstakes Period,
              time permitting. If Sponsor is unable to confirm any winner after
              three (3) good faith attempts, the prize may not be awarded. Each
              potential winner may be required to furnish proof of
              identification and other proof of eligibility under these Official
              Rules. Sweepstakes Entities shall not be responsible or liable for
              entries that are entered by other than human means (such as by an
              automated computer program or other non-human mechanism, entity or
              device), in excess of the stated limit, or for entries that are
              late, forged, destroyed, lost, misplaced, stolen, misdirected,
              tampered with, incomplete, deleted, damaged, garbled, or otherwise
              not in compliance with the Official Rules, and all such entries
              will be disqualified. The right to receive a prize is
              non-assignable, non-transferable and no prize substitution,
              exchange or cash equivalent will be allowed, except by Sponsor who
              reserves the right to substitute a prize of equal or greater value
              in case of the unavailability of a prize or force majeure.
              <b>
                Federal, state and local taxes, if any, are solely the
                responsibility of the winner. Mercury will issue an IRS Form
                1099-MISC to the winner.
              </b>
              The ARV is as of the time the Official Rules were printed and the
              value of the prize may fluctuate. The winner is not entitled to
              any difference between the ARV and the actual value of the prize
              at the time the prize is awarded. All other costs and expenses not
              expressly set forth herein shall be solely each winner’s
              responsibility. The Sweepstakes Entities shall not be held
              responsible for any delays in awarding a prize for any reason.
              Prizes will only be awarded to verified winners
            </span>
          </p>
        </div>
        <div className="policy">
          <span>7.</span>
          <p>
            <span>LIMITATION OF LIABILITY:</span>By participating in this
            Sweepstakes, Entrants agree that the Sweepstakes Entities and each
            of their respective representatives, consultants, contractors, legal
            counsel, advertising, public relations, promotional, fulfillment and
            marketing agencies, website providers and each their respective
            officers, directors, stockholders, employees, representatives,
            designees and agents (“Released Parties”) are not responsible for:
            (i) lost, late, incomplete, stolen, misdirected, postage due or
            undeliverable e-mail/text notifications or postal mail; (ii) any
            computer, telephone, satellite, cable, network, electronic or
            Internet hardware or software malfunctions, failures, connections,
            or availability; (iii) garbled, corrupt or jumbled transmissions,
            service provider/network accessibility, availability or traffic
            congestion; (iv) any technical, mechanical, printing or
            typographical or other error; (v) the incorrect or inaccurate
            capture of registration information or the failure to capture, or
            loss of, any such information; (vi) any error, omission,
            interruption, deletion, defect, delay in operation or transmission,
            communications line failure, technical error, theft or destruction
            or unauthorized access to the Sweepstakes; (vii) any injury or
            damage, whether personal or property, to Entrants or to any person's
            computer related to or resulting from participating in the
            Sweepstakes and/or accepting a prize; and (viii) entries that are
            late, forged, lost, misplaced, misdirected, tampered with,
            incomplete, deleted, damaged, garbled or otherwise not in compliance
            with the Official Rules. Further, the Sweepstakes Entities are not
            responsible for any unanswered or undeliverable winner
            notifications.
            <span>
              By entering the Sweepstakes, each Entrant agrees: (i) to be bound
              by these Official Rules, including entry requirements; (ii) to
              waive any rights to claim ambiguity with respect to these Official
              Rules; (iii) to waive all of his/her rights to bring any claim,
              action or proceeding against any of the Released Parties in
              connection with the Sweepstakes; and (iii) to forever and
              irrevocably agree to release and hold harmless each of the
              Released Parties from any and all claims, lawsuits, judgments,
              causes of action, proceedings, demands, fines, penalties,
              liability, costs and expenses (including, without limitation,
              reasonable attorneys’ fees) that may arise in connection with: (a)
              the Sweepstakes, including, but not limited to, any
              Sweepstakes-related activity or element thereof, and the Entrant’s
              entries, participation or inability to participate in the
              Sweepstakes; (b) the violation of any third-party privacy,
              personal, publicity or proprietary rights; (c) acceptance,
              attendance at, receipt, travel related to, participation in,
              delivery of, possession, defects in, use, non-use, misuse,
              inability to use, loss, damage, destruction, negligence or willful
              misconduct in connection with the use of a prize (or any component
              thereof); (d) any change in the prizing (or any components
              thereof); (e) human error; (f) any wrongful, negligent, or
              unauthorized act or omission on the part of any of the Released
              Parties; (g) lost, late, stolen, misdirected, damaged or destroyed
              prizing (or any element thereof); or (h) the negligence or willful
              misconduct by Entrant.
            </span>
            <span>
              If, for any reason, the Sweepstakes is not capable of running as
              planned, Sponsor reserves the right, in its sole and absolute
              discretion, to cancel, terminate, modify or suspend the
              Sweepstakes and/or proceed with the Sweepstakes, including the
              selection of a winner in a manner it deems fair and reasonable,
              including the selection of a winner from among eligible entries
              received prior to such cancellation, termination, modification or
              suspension. In no event will more prizes be awarded than are
              stated in these Official Rules. In the event that, due to
              technical, typographical, mechanical, administrative, printing,
              production, computer or other errors, there are more winners than
              are stated in these Official Rules, a random drawing among the
              claimants will be held to determine the winner
            </span>
            <span>
              WITHOUT LIMITING THE FOREGOING, EVERYTHING REGARDING THIS
              PROMOTION, INCLUDING THE PRIZE, IS PROVIDED “AS IS” SUBJECT TO ANY
              WARRANTY PROVIDED BY SPONSOR.{" "}
            </span>
          </p>
        </div>
        <div className="policy">
          <span>8.</span>
          <p>
            <span>DISPUTES:</span>
            <b>
              THIS PROMOTION IS GOVERNED BY, AND WILL BE CONSTRUED IN ACCORDANCE
              WITH, THE LAWS OF THE STATE OF MICHIGAN, AND THE FORUM AND VENUE
              FOR ANY DISPUTE SHALL BE IN WAYNE COUNTY, MICHIGAN. IF THE
              CONTROVERSY OR CLAIM IS NOT OTHERWISE RESOLVED THROUGH DIRECT
              DISCUSSIONS OR MEDIATION, IT SHALL THEN BE RESOLVED BY FINAL AND
              BINDING ARBITRATION ADMINISTERED BY JAMS IN ACCORDANCE WITH ITS
              ARBITRATION RULES AND PROCEDURES OR SUBSEQUENT VERSIONS THEREOF
              (“JAMS RULES”). THE JAMS RULES FOR SELECTION OF AN ARBITRATOR
              SHALL BE FOLLOWED, EXCEPT THAT THE ARBITRATOR SHALL BE EXPERIENCED
              AND LICENSED TO PRACTICE LAW IN MICHIGAN. ALL PROCEEDINGS BROUGHT
              PURSUANT TO THIS PARAGRAPH WILL BE CONDUCTED IN WAYNE COUNTY,
              MICHIGAN. THE REMEDY FOR ANY CLAIM SHALL BE LIMITED TO ACTUAL
              DAMAGES, AND IN NO EVENT SHALL ANY PARTY BE ENTITLED TO RECOVER
              PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR HAVE
              DAMAGES MULTIPLIED OR OTHERWISE INCREASED, INCLUDING ATTORNEYS’
              FEES OR OTHER SUCH RELATED COSTS OF BRINGING A CLAIM, OR TO
              RESCIND THIS AGREEMENT OR SEEK INJUNCTIVE OR ANY OTHER EQUITABLE
              RELIEF. ENTRANTS AGREE THAT THE RIGHTS AND OBLIGATIONS OF ANY
              ENTRANT AND/OR PROMOTION ENTITIES AND/OR ANY OTHER PARTY SHALL BE
              RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION.
              ANY DEMAND FOR ARBITRATION MUST BE FILED WITHIN ONE (1) YEAR FROM
              THE END OF THE PROMOTION PERIOD, OR THE CAUSE OF ACTION SHALL BE
              FOREVER BARRED.
            </b>
          </p>
        </div>
        <div className="policy">
          <span>9.</span>
          <p>
            <span>PRIVACY:</span>Personal information collected from Entrants is
            subject to the Ford Motor Company’s Privacy Policy located on{" "}
            <Link className="link" to="https://www.bar-s.com/privacy-policy">
              https://www.bar-s.com/privacy-policy
            </Link>{" "}
            By entering the Sweepstakes, you expressly agree to the terms of the
            Privacy Policy
          </p>
        </div>
        <div className="policy">
          <span>10.</span>
          <p>
            <span>PUBLICITY RIGHTS:</span>By participating in this Sweepstakes,
            each Entrant agrees to allow the Sponsor and Sponsor’s designee the
            perpetual right to use his/her name, address (city and state),
            biographical information, likeness, picture and other information
            and content provided in connection with the Sweepstakes for
            Sweepstakes, trade, commercial, advertising and publicity purposes
            in all media worldwide including, but not limited to, on television
            and the Internet, without notice, review or approval and without
            additional compensation, except where prohibited by law.
          </p>
        </div>
        <div className="policy">
          <span>11.</span>
          <p>
            <span>GENERAL</span>
            Any attempted form of participation in this Sweepstakes other than
            as described herein is void. Sponsor reserves the right to
            disqualify any Entrant found or suspected, in their sole and
            absolute discretion, to be tampering with the operation of the
            Sweepstakes; to be acting in violation of these Official Rules; or
            to be acting in an unsportsmanlike manner or with the intent to
            disrupt the normal operation of this Sweepstakes. Any attempted form
            of participation in this Sweepstakes other than as in these Official
            Rules is void. If it is discovered that a person has registered or
            attempted to register more than once using multiple phone numbers,
            e-mail addresses, residential addresses, multiple identities, IP
            addresses, use of proxy servers, or like methods, all of that
            person’s entries will be declared null and void and that person will
            not be awarded any prize that he/she might have been entitled to
            receive. Any use of robotic, automatic, macro, programmed, third
            party or like methods to participate in the Sweepstakes will void
            any attempted participation effected by such methods and the
            disqualification of the individual utilizing the same.{" "}
            <b>
              CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE ANY WEBSITE OR
              UNDERMINE THE LEGITIMATE OPERATION OF THE SWEEPSTAKES IS A
              VIOLATION OF CRIMINAL AND CIVIL LAWS. SHOULD SUCH AN ATTEMPT BE
              MADE, THE SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES OR OTHER
              REMEDIES FROM ANY SUCH PERSON(S) RESPONSIBLE FOR THE ATTEMPT TO
              THE FULLEST EXTENT PERMITTED BY LAW.
            </b>
            If any provision of these Official Rules or any word, phrase,
            clause, sentence, or other portion thereof should be held
            unenforceable or invalid for any reason, then that provision or
            portion thereof shall be modified or deleted in such manner as to
            render the remaining provisions of these Official Rules valid and
            enforceable. The invalidity or unenforceability of any provision of
            these Official Rules or the prize documents will not affect the
            validity or enforceability of any other provision. No Entrant shall
            have the right to modify or amend these Official Rules. Sponsor’s
            failure to enforce any term of these Official Rules shall not
            constitute a waiver of that provision and such provision shall
            remain in full force and effect. All entries and/or materials
            submitted become the property of Sponsor and will not be returned.
            In the event of any conflict with any Sweepstakes details contained
            in these Official Rules and Sweepstakes details contained in any
            promotional materials (including but not limited to point of sale,
            television and print advertising, promotional packaging and other
            promotional media), the details of the Sweepstakes as set forth in
            these Official Rules shall prevail.
          </p>
        </div>
        <div className="policy">
          <span>12.</span>
          <p>
            <span>WINNER LIST:</span>
            To learn the name of the Winner, interested individuals should mail
            a self-addressed stamped business envelope to: Bar-S Foods Now That
            Sounds Good – Iconic Summer Mashup Sweepstakes Winner List, 35610
            Mound Road, Dept 900, Sterling Heights MI 48310. Winners List
            requests must be received no later than July 15, 2021.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
